@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
    --header-height: 100px;
    /* --nav-width: 68px; */
    --first-color: #ffffff;
    /* --first-color-light: #AFA5D9; */
    --white-color: #000000;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

*,
::before,
::after {
    box-sizing: border-box
}

body {
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .5s;
}

a {
    text-decoration: none
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: #ffffff !important;
    z-index: 200;
    transition: .5s;
   
}

.header_toggle {
    color: var(--white-color);
    font-size: 2rem;
    cursor: pointer;
    display:none;
}

.header_img {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.header_img img {
    width: 40px
}

.header_item {
    display: flex;
    justify-content: center;
    overflow: hidden;
    
}
.welcome_name{
    font-weight: 600;
    font-size: 20px;
    color: #000;
    width: 20rem;
}

.user_heading_div {
    margin-left: 10rem;
}
.img_logo{
    height: 55px;
    margin-left: 30px;

}

.heading_table {
    font-size: 1.2rem;
    font-weight: 600;
}
.nav_logo-name {
    /* color: #804a42;
    font-weight: 600;
    font-size: 30px; */
    font-weight: 600;
font-size: 1.6rem;
line-height: 23px;
color: #000;
margin-top:20px;
margin-left: 10px;
}
.nav_input-name {
    width: 50rem !important;
    height: 3.5rem;
    margin-bottom: 2rem;
}
.input_search {
    width: 35rem;
    height: 3.5rem;
    font-size: 1.5rem !important;
    text-align: left !important;
    padding-left: 1rem;
    border: 1px solid black;
    border-radius: 1rem;
    background-color: #f5f5f5;
    outline: none;
}
.input_search:hover {
    background-color: #fff;
}
.search_icon_img {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -3rem;
    padding-top: 1rem; 
}
/* .nav-logo {
    background-color: none;
} */
/* .search_logo {
    height: 1.2rem;
     width: auto;
   
} */
/* .btn_status {
    width: 9.4rem;
    height: 2.6rem;
    background: #2EC4B6;
    border-radius: 16px;
    color: white;
    margin-right: 10px;
} */
/* .btn_status_div {
width: 151px;
height: 43px;
background: #2EC4B6;
border-radius: 15px;
color: white;
margin-right: 10px;
} */
.btn_create_user{
    background: #2EC4B6;
    border-radius: 15px;
    width: 186px;
height: 44px;
outline: none;
border: none;
color: white;
font-weight: 600;
font-size: 14px;
line-height: 29px;
margin-bottom: 30px;
}
.nav_list{
    margin-top:120px;
    margin-left: 1rem;
    height : 100%;
}
.l-navbar {
    position: fixed;
    top: 0;
    width: 15rem;
    height: 100%;
    background-color: var(--first-color);
    /* padding: .5rem 1rem 10 10; */
    transition: .5s;
    /* z-index: var(--z-fixed); */
    box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
   
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.nav_logo{
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
    font-size: 15px;
    font-weight: 700;
}
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
    font-size: 15px;
    font-weight: 700;
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}



.nav_logo:hover {
    color: #000000;
    text-decoration: none !important;
}

.nav_link {
    position: relative;
    color: #1f1f1f;
    margin-bottom: 1rem;
    /* transition: .3s; */
    font-weight: 500;
font-size: 14px;
line-height: 29px;
text-decoration: none;
}
.active{
    color: var(--white-color);
    background-color: #E5F6EF;
padding: 10px 20px;
border-radius: 15px;
margin-left: 5px;
text-decoration: none;
}
.nav_link:hover {
    color: var(--white-color);
    text-decoration: none;
}

.nav_icon {
    font-size: 1.25rem
}

/* .show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
} */



/* .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
} */

.height-100 {
    height: 100vh
}
.input_search {
    text-align: center;
    font-size: 1.5rem;
}
.status_div {
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .logo_id_div {
        height: 10rem !important;
        width: 12rem;
        margin-top: 4rem;
    }
    .header_img {
        width: 2.5rem;
        height: 2.5rem;
    }

    .header_img img {
        width: 2.7rem;
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
        /* width: 12rem; */
    }


    #body-pd {
        /* padding-left: calc(var(--nav-width) + 180px) */
   /* margin-left: 150px;
   margin-top: 20px; */
margin: 20px 50px 0 180px;
padding-right: 100px;
width:100%;
    }
    .nav_list {
      text-align: center;
      margin-left: .5rem;
      height : 100%;
    }
    /* .header_item {
        margin-left: 3rem;
    } */
    .img_logo {
        margin-left: 1rem;
    }
    .nav-logo {
        margin-left: 2rem;
    }
   .welcome_name {
       margin-left: 5rem;
   }
   .header_item h5 {
       margin-left: 3rem;
   }
   .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
   width: 15rem;
   margin-right: 20rem;
   }
   .input_search {
       margin-left: 45rem;
   }
   .welcome_name {
       min-width: 10rem;
       margin-left: 1rem;
   }
   .status_div {
       text-align: center; 
   }
  .card_div {
  }
 .total_div {
     margin-top: 2rem;
 }
  .total_status_div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
  }
  .container1_div {
      margin-top: 5rem;
  }
  .view_btn {
      width: 3rem !important;
  }
  .card_id_div {
      width: 40rem !important;
  }
  .quote_div {
      font-size: .8rem !important;
      margin-top: 1rem;
      text-align: center;
  }
 
    .login_image {
       display: none;
    }
    .body_login_div {
        display: flex;
       justify-content: center !important;
       align-items: center !important;
    }
    .login_user_div {
        width: 60rem !important;
        height: auto;
     display: flex;
     flex-direction: column !important;
     justify-content: center !important;
     margin-top: 10rem;
      }
      .swn_div{
        margin-right: 10rem;
      }
      .sign_text {
          margin-right: 10rem;
      }
   } 

 /* // media queries */
 @media screen and (min-width: 992px) and (max-width: 1200px) {
    .logo_id_div {
        height: 10rem !important;
        width: 12rem;
        margin-top: 4rem;
    }

    .header_img {
        width: 2.5rem;
        height: 2.5rem;
    }

    .header_img img {
        width: 2.7rem;
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
        /* width: 12rem; */
    }


    #body-pd {
        /* padding-left: calc(var(--nav-width) + 180px) */
   /* margin-left: 150px;
   margin-top: 20px; */
margin: 20px 50px 0 180px;
padding-right: 100px;
width:100%;
    }
    .nav_list {
      text-align: center;
      margin-left: .5rem;
      height : 100%;
    }
    /* .header_item {
        margin-left: 3rem;
    } */
    .img_logo {
        margin-left: 1rem;
    }
    .nav-logo {
        margin-left: 2rem;
    }
   .welcome_name {
       margin-left: 5rem;
   }
   .header_item h5 {
       margin-left: 3rem;
   }
   .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
   width: 15rem;
   margin-right: 20rem;
   }
   .input_search {
       margin-left: 25rem;
   }
   .welcome_name {
       min-width: 10rem;
       margin-left: 1rem;
   }
   .container1_div {
    margin-top: 5rem;
}
/* .login_user_div {
    margin-left: 7rem;
} */
.body_login_div {
    margin-left: 7rem;
}
.login_image {
    position: absolute;
 }
 }
 @media screen and (min-width: 1201px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 2.5rem;
        height: 2.5rem;
    }

    .header_img img {
        width: 2.7rem;
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
        /* width: 12rem; */
    }

    /* .show {
        width: calc(var(--nav-width) + 156px)
    } */

    #body-pd {
        /* padding-left: calc(var(--nav-width) + 180px) */
   /* margin-left: 150px;
   margin-top: 20px; */
margin: 20px 50px 0 180px;
padding-right: 100px;
width:100%;
    }
    .nav_list {
      text-align: center;
      margin-left: .5rem;
      height : 100%;
    }
    /* .header_item {
        margin-left: 3rem;
    } */
    .img_logo {
        margin-left: 1rem;
    }
    .nav-logo {
        margin-left: 2rem;
    }
   .welcome_name {
       margin-left: 5rem;
   }
   .header_item h5 {
       margin-left: 3rem;
   }
   .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
   width: 15rem;
   }
   .input_search {
       margin-left: 25rem;
   }
   .welcome_name {
       min-width: 10rem;
       margin-left: 1rem;
   }
 }


 @media screen and (min-width: 360px) and (max-width: 767px) {

 .total_div {
     margin-top: 1.5rem;
 }
 .total_status_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.para_div {
    font-size: 10px;
}
    .view_btn {
        width: 4rem !important;
        font-size: 10px;
    }
    .btn_status {
        width: 7rem;
        height: 2.6rem;
        background: #2EC4B6;
        border-radius: 16px;
        color: white;
        margin-right: 10px;
        font-size: 12px !important;
    }
    .card_div {
        width: 65%;
        margin-bottom: 2rem;
    }
    .dis_num {
        font-size: 20px;
    }
    .active {
        background: none;
        text-decoration: none;
    }
    .brand_logo_div  {
        font-size: 1.5rem;
        margin-top: -1rem;
    }
    .btn_st_div {
        display: flex;
        flex-direction: column !important;
        width: 10rem;
       margin: auto;
    }
    .btn_status_div4 {
         margin-bottom: 20px !important;
         display: flex;
         justify-content: center !important;
         align-items: center;
    }
    .card_table_div {
        display: flex;
        flex-direction: column !important;
        width: 35rem;
    }
    .card_body_div {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        height: 20rem;
    }
    .heading_table {
 display: flex;
 flex-direction: column !important;
    }
    .card_id_div {
        width: 20rem !important;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .user_image_div{
        width: 6rem !important;
        height: 6rem !important;
        display: flex;
        margin-left: 5rem;
        margin-bottom: 2rem;
    }
    .quote_div {
        display: flex;
        justify-content: center;
    }
    .logo_id_div {
        width: 12rem;
        height: 9rem;
        margin-top: 10rem;
       margin-left: -5.5rem;
    }
    .login_user_div {
        width: 40rem !important;
        height: auto;
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin-top: 10rem;
      }
      .input_login_form {
          width: 25rem !important;
          height: 2.5rem;
          margin-left: 7rem;
          background: black;
      }
      .label_login_div {
          margin-left: 8rem;
      }
      .submit_login_div {
          width: 25rem !important;
          margin-left: 7rem;
      }
      .body_login_div {
          /* margin-left: 10rem; */
          margin-top: -5rem;
      }
      .valid_login_div {
          width: 25rem;
          margin-left: 8rem !important;
      }
      .login_image {
        display: none;
      }
}